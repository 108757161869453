<template>
  <div class="l-file-upload">
    <!-- ===================================== -->
    <div class="upload-file">
      <div class="upload-file__top">
        <span>{{ $t("File Name") }}</span>

        <div @click="$refs.refLFileUploadInput.click()">
          <feather-icon
            icon="PlusIcon"
            size="24"
            class="cursor-pointer border-dotted feather-add-icon"
          />
          <input
            v-show="false"
            ref="refLFileUploadInput"
            :accept="formattedAcceptProps"
            type="file"
            @input="fileInputChanged"
          >
        </div>
      </div>
    </div>
    <!-- ===================================== -->
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    acceptExtensionArray: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    formattedAcceptProps() {
      return this.acceptExtensionArray.join(',')
    },
  },
  methods: {
    dropped(e) {
      this.$emit('input', this.onlyAcceptableFiles(e.dataTransfer.files))
    },
    onlyAcceptableFiles(filesArray) {
      // todo add check for it
      return filesArray
    },
    fileInputChanged(e) {
      this.$emit('input', e.target.files)
    },
    clearInputValue() {
      this.$refs.refLFileUploadInput.value = null
    },
  },
}
</script>
